import React, { Component } from "react";

import Slide from "react-reveal";


class Portfolio extends Component {
  render() {
    if (!this.props.data) return null;

    

      

    return (
      <section id="whitepaper">
        <Slide left duration={1000}>
          <div className="row">
          <a href="https://dopedupdroids.gitbook.io/doped-up-droids/">
          
          <button className="glow-on-hover" type="button">The Algorithm</button>
</a>
         
          </div>
        </Slide>
      </section>
    );
  }
}

export default Portfolio;

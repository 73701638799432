import React, { Component } from "react";

import cons from "./cons.jpg"
var sectionStyle = {
  
  backgroundImage: `url(${cons})`
};

class Roadmap extends Component {
  render() {
    if (!this.props.data) return null;

     
    
    return (
      <section id="roadmap" style= {sectionStyle}>
        
      <h1 className = "rh">Loading...roadmap</h1>
        

      </section>
    );
  }
}

export default Roadmap;

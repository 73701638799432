import React, { Component } from "react";
import { Fade} from "react-reveal";
import member1 from "./molly.png"
import member2 from "./soldog.png"
import member3 from './wiseoldsol.png'
class Contact extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const phone = this.props.data.phone;
    const message = this.props.data.contactmessage;

    return (
      <section id="team">
        
        <Fade bottom duration={1000}>
        <div className="wrapper">
    <h1>Our Team</h1>
    <div className="our_team">
        <div className="team_member">
          <div className="member_img">
             <img src={member1} alt="our_team"/>
            <div className="social_media">
               
               <div className="twitter item"><a href="https://twitter.com/mollyonsol"><i className="fab fa-twitter"></i></a></div>
               
             </div>
          </div>
          <h3>Molly</h3>
          <span>The Motherboard</span>
          <p>The dopest doper</p>
        </div>
        <div className="team_member">
           <div className="member_img">
             <img src={member3} alt="our_team"/>
             <div className="social_media">
               
               <div className="twitter item"><a href='https://twitter.com/wiseoldSOL'><i className="fab fa-twitter"></i></a></div>
               
             </div>
          </div>
          <h3>WiseOldSol</h3>
          <span>The Graphics Card</span>
          <p>Dopes with the Doped Crew</p>
      </div>
        <div className="team_member">
           <div className="member_img">
             <img src={member2} alt="our_team"/>
             <div className="social_media">
              
               <div className="twitter item"><a href='https://twitter.com/soldog333'><i className="fab fa-twitter"></i></a></div>
               
             </div>
          </div>
          <h3>Soldog</h3>
          <span>The CPU</span>
          <p>The dopiest tech geek around</p>
      </div>
        
          
          
    </div>
</div>	
          
          
        
        </Fade>
      </section>
    );
  }
}

export default Contact;
